import React, { FC } from 'react';
import { PageProps, graphql } from 'gatsby';
import classnames from 'classnames';
import { MainLayout } from '../../templates/MainLayout';
import styles from './styles.module.scss';
import { PostCard } from '../../components/blog/BlogPostCard';
import { IPostsNewQuery, IPostsNewQueryEdge } from '../../queries/posts/types';
import SubscriptionForm from '../../components/common/SubscriptionForm';
import { ReCaptchaProvider } from '../../components/common/ReCaptchaProvider';

type IProps = PageProps<
  IPostsNewQuery,
  { slug: string; name: string; locale: string }
>;

const BlogPage: FC<IProps> = ({
  data,
  location,
  pageContext: { slug, name, locale },
}) => {
  const posts: IPostsNewQueryEdge[] = data.allStrapiPost.edges;

  return (
    <ReCaptchaProvider>
      <MainLayout locale={locale} title={name}>
        <div className={classnames('content', styles.wrap)}>
          <div className={styles.grid}>
            {posts
              .filter(({ node }) => node.blog.slug === slug)
              .map(({ node }) => (
                <PostCard
                  key={node.id}
                  url={`${location.pathname}/${node.slug}`}
                  title={node.title}
                  featuredImage={`${process.env.GATSBY_STRAPI_API_URL}${node.cover.formats?.small?.url}`}
                  date={node.date}
                  from={location.pathname}
                />
              ))}
            <SubscriptionForm />
          </div>
        </div>
      </MainLayout>
    </ReCaptchaProvider>
  );
};

export default BlogPage;

export const query = graphql`
  query ($locale: String) {
    allStrapiPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          title
          slug
          locale
          date(formatString: "DD MMMM YYYY", locale: $locale)
          cover {
            formats {
              small {
                url
              }
            }
          }
          blog {
            slug
          }
        }
      }
    }
  }
`;
